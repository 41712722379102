import {
  Flex,
  Heading,
  IconButton,
  ListItem,
  OrderedList,
  Link,
} from "@chakra-ui/react";
import { graphql, Link as GatsbyLink } from "gatsby";
import React from "react";
import { Header } from "../../components/Header";
import { Layout } from "../../components/Layout";
import { Main } from "../../components/Main";
import { HeaderBar } from "../../components/HeaderBar";
import { SectionDescription } from "../../features/inspirations/SectionDescription";
import { FaCloudUploadAlt } from "react-icons/fa";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Header>
        <HeaderBar
          rightContent={
            <IconButton
              aria-label="Upload Resource"
              colorScheme="green"
              icon={<FaCloudUploadAlt />}
              as={Link}
              href="https://forms.gle/ZNuC9LjDWKtBGYVg9"
              isExternal
            />
          }
        />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={["100%", null, null, "120ch"]}
        >
          <SectionDescription />
          <OrderedList marginX="4">
            {data.allMdx.edges.map((edge, i) => {
              const { frontmatter, id, slug } = edge.node;
              const { title, community, name } = frontmatter;
              return (
                <ListItem
                  display="list-item"
                  flexDirection="column"
                  my="8"
                  marginLeft="2rem"
                  key={id}
                >
                  <Link
                    textDecoration="underline"
                    as={GatsbyLink}
                    to={`/inspirations/${slug}`}
                  >
                    <Heading as="h3" color="zorba" fontSize="xl">
                      {title}
                    </Heading>
                  </Link>
                </ListItem>
              );
            })}
          </OrderedList>
        </Flex>
      </Main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query inspirations {
    allMdx(filter: { fileAbsolutePath: { regex: "/content/inspirations/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            name
            community
            date
          }
          slug
        }
      }
    }
  }
`;
